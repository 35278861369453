import { Env } from '@tmf-env/types';
import { envCommon } from './env.common';

export const env: Env = {
  ...(envCommon as Env),
  brandId: 'jz',
  graphqlApiUrl: 'https://api.januszcasino{versionNumber}.com/gql/',
  graphqlWsUrl: 'wss://api.januszcasino{versionNumber}.com/wss/',
  gameAssetsBaseUrl: 'https://api.januszcasino{versionNumber}.com/game-assets/',
  cmsApiUrl: 'https://cms.januszcasino{versionNumber}.com/',
  allGamesCollection: 'jz_all-games',
  allowedCountries: ['PL', 'MT', 'DE', 'HU'],
  svgIconConfig: {
    brand: 'januszcasino',
    hostUrl: 'https://januszcasino{versionNumber}.com/'
  },
  webpConfig: {
    brand: 'januszcasino',
    hostUrl: 'https://januszcasino{versionNumber}.com/'
  }
};
