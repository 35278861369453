type Ctor = abstract new (...args: any) => any;

function registerStore(): void {
  const globalAny: any = globalThis;
  if (!globalAny['_diStore']) {
    globalAny['_diStore'] = new Map();
  }
}

function getStore<T extends Ctor>(): Map<T, InstanceType<T> | undefined> {
  const globalAny: any = globalThis;
  return globalAny['_diStore'];
}

export function provideSingleton<T extends Ctor>(klazz: T, singletonFactory: () => InstanceType<T>): InstanceType<T> {
  registerStore();
  const diStore: Map<T, InstanceType<T> | undefined> = getStore<T>();
  if (!diStore.has(klazz)) {
    diStore.set(klazz, singletonFactory());
  }
  return diStore.get(klazz)!;
}
